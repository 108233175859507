import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var beds = 2;
		let el = document.querySelector("[data-beds]");
		if (el) {
			beds = parseInt(el.getAttribute('data-beds'), 10);
		} else {
			console.log('element is not found..................');
		}
		console.log('before region id..................');
		let e2 = document.querySelector("[data-region-id]");
		console.log('region id :' + e2.getAttribute('data-region-id'));
		var regionId;
		if (e2) {
			regionId = parseInt(e2.getAttribute('data-region-id'));

			console.log('region id int:' + regionId);
		} else {
			console.log('element is not found..................');
		}
		var req = {
			fields: this.resultFields,
			filter: {
				'beds': beds
					//'region.id': regionId

			},
			max: 6,
			sorting: 'random'
		};


		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;

			});


	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			$(".owl-carousel.similar-units").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};